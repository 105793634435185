@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable.095e28c4.ttf");
}

@font-face {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: 100 800;
  font-display: swap;
  src: url("jetbrainsmono.b025158e.ttf");
}

@font-face {
  font-family: Material Symbols Rounded;
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url("symbols.4937e523.woff2") format("woff2"), url("symbols.cb135040.ttf") format("truetype");
}

:root {
  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;
  --slate-950: #020617;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --gray-950: #030712;
  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;
  --zinc-950: #09090b;
  --neutral-50: #fafafa;
  --neutral-100: #f5f5f5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-600: #525252;
  --neutral-700: #404040;
  --neutral-800: #262626;
  --neutral-900: #171717;
  --neutral-950: #0a0a0a;
  --stone-50: #fafaf9;
  --stone-100: #f5f5f4;
  --stone-200: #e7e5e4;
  --stone-300: #d6d3d1;
  --stone-400: #a8a29e;
  --stone-500: #78716c;
  --stone-600: #57534e;
  --stone-700: #44403c;
  --stone-800: #292524;
  --stone-900: #1c1917;
  --stone-950: #0c0a09;
  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;
  --red-950: #450a0a;
  --orange-50: #fff7ed;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
  --orange-950: #431407;
  --amber-50: #fffbeb;
  --amber-100: #fef3c7;
  --amber-200: #fde68a;
  --amber-300: #fcd34d;
  --amber-400: #fbbf24;
  --amber-500: #f59e0b;
  --amber-600: #d97706;
  --amber-700: #b45309;
  --amber-800: #92400e;
  --amber-900: #78350f;
  --amber-950: #451a03;
  --yellow-50: #fefce8;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;
  --yellow-950: #422006;
  --lime-50: #f7fee7;
  --lime-100: #ecfccb;
  --lime-200: #d9f99d;
  --lime-300: #bef264;
  --lime-400: #a3e635;
  --lime-500: #84cc16;
  --lime-600: #65a30d;
  --lime-700: #4d7c0f;
  --lime-800: #3f6212;
  --lime-900: #365314;
  --lime-950: #1a2e05;
  --green-50: #f0fdf4;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;
  --green-950: #052e16;
  --emerald-50: #ecfdf5;
  --emerald-100: #d1fae5;
  --emerald-200: #a7f3d0;
  --emerald-300: #6ee7b7;
  --emerald-400: #34d399;
  --emerald-500: #10b981;
  --emerald-600: #059669;
  --emerald-700: #047857;
  --emerald-800: #065f46;
  --emerald-900: #064e3b;
  --emerald-950: #022c22;
  --teal-50: #f0fdfa;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4;
  --teal-300: #5eead4;
  --teal-400: #2dd4bf;
  --teal-500: #14b8a6;
  --teal-600: #0d9488;
  --teal-700: #0f766e;
  --teal-800: #115e59;
  --teal-900: #134e4a;
  --teal-950: #042f2e;
  --cyan-50: #ecfeff;
  --cyan-100: #cffafe;
  --cyan-200: #a5f3fc;
  --cyan-300: #67e8f9;
  --cyan-400: #22d3ee;
  --cyan-500: #06b6d4;
  --cyan-600: #0891b2;
  --cyan-700: #0e7490;
  --cyan-800: #155e75;
  --cyan-900: #164e63;
  --cyan-950: #083344;
  --sky-50: #f0f9ff;
  --sky-100: #e0f2fe;
  --sky-200: #bae6fd;
  --sky-300: #7dd3fc;
  --sky-400: #38bdf8;
  --sky-500: #0ea5e9;
  --sky-600: #0284c7;
  --sky-700: #0369a1;
  --sky-800: #075985;
  --sky-900: #0c4a6e;
  --sky-950: #082f49;
  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;
  --blue-950: #172554;
  --indigo-50: #eef2ff;
  --indigo-100: #e0e7ff;
  --indigo-200: #c7d2fe;
  --indigo-300: #a5b4fc;
  --indigo-400: #818cf8;
  --indigo-500: #6366f1;
  --indigo-600: #4f46e5;
  --indigo-700: #4338ca;
  --indigo-800: #3730a3;
  --indigo-900: #312e81;
  --indigo-950: #1e1b4b;
  --violet-50: #f5f3ff;
  --violet-100: #ede9fe;
  --violet-200: #ddd6fe;
  --violet-300: #c4b5fd;
  --violet-400: #a78bfa;
  --violet-500: #8b5cf6;
  --violet-600: #7c3aed;
  --violet-700: #6d28d9;
  --violet-800: #5b21b6;
  --violet-900: #4c1d95;
  --violet-950: #2e1065;
  --purple-50: #faf5ff;
  --purple-100: #f3e8ff;
  --purple-200: #e9d5ff;
  --purple-300: #d8b4fe;
  --purple-400: #c084fc;
  --purple-500: #a855f7;
  --purple-600: #9333ea;
  --purple-700: #7e22ce;
  --purple-800: #6b21a8;
  --purple-900: #581c87;
  --purple-950: #3b0764;
  --fuchsia-50: #fdf4ff;
  --fuchsia-100: #fae8ff;
  --fuchsia-200: #f5d0fe;
  --fuchsia-300: #f0abfc;
  --fuchsia-400: #e879f9;
  --fuchsia-500: #d946ef;
  --fuchsia-600: #c026d3;
  --fuchsia-700: #a21caf;
  --fuchsia-800: #86198f;
  --fuchsia-900: #701a75;
  --fuchsia-950: #4a044e;
  --pink-50: #fdf2f8;
  --pink-100: #fce7f3;
  --pink-200: #fbcfe8;
  --pink-300: #f9a8d4;
  --pink-400: #f472b6;
  --pink-500: #ec4899;
  --pink-600: #db2777;
  --pink-700: #be185d;
  --pink-800: #9d174d;
  --pink-900: #831843;
  --pink-950: #500724;
  --rose-50: #fff1f2;
  --rose-100: #ffe4e6;
  --rose-200: #fecdd3;
  --rose-300: #fda4af;
  --rose-400: #fb7185;
  --rose-500: #f43f5e;
  --rose-600: #e11d48;
  --rose-700: #be123c;
  --rose-800: #9f1239;
  --rose-900: #881337;
  --rose-950: #4c0519;
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--slate-50);
  --main-100: var(--slate-100);
  --main-200: var(--slate-200);
  --main-300: var(--slate-300);
  --main-400: var(--slate-400);
  --main-500: var(--slate-500);
  --main-600: var(--slate-600);
  --main-700: var(--slate-700);
  --main-800: var(--slate-800);
  --main-900: var(--slate-900);
  --main-950: var(--slate-950);
  accent-color: var(--accent);
  --accentColor-50: var(--sky-50);
  --accentColor-100: var(--sky-100);
  --accentColor-200: var(--sky-200);
  --accentColor-300: var(--sky-300);
  --accentColor-400: var(--sky-400);
  --accentColor-500: var(--sky-500);
  --accentColor-600: var(--sky-600);
  --accentColor-700: var(--sky-700);
  --accentColor-800: var(--sky-800);
  --accentColor-900: var(--sky-900);
  --accentColor-950: var(--sky-950);
}

@media not print {
  :root {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }
}

@media print {
  :root {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --main-50: var(--neutral-50);
    --main-100: var(--neutral-100);
    --main-200: var(--neutral-200);
    --main-300: var(--neutral-300);
    --main-400: var(--neutral-400);
    --main-500: var(--neutral-500);
    --main-600: var(--neutral-600);
    --main-700: var(--neutral-700);
    --main-800: var(--neutral-800);
    --main-900: var(--neutral-900);
    --main-950: var(--neutral-950);
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }
}

:root {
  --font-family: "Inter", sans-serif;
  color: var(--text);
  font-family: var(--font-family);
  font-feature-settings: "liga" 1, "calt" 1;
}

@media not print {
  :root {
    background-color: var(--background);
  }
}

input, button, textarea, select {
  font-family: var(--font-family);
  color: var(--text);
}

@media not print {
  .light {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }
}

@media print {
  .light {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --main-50: var(--neutral-50);
    --main-100: var(--neutral-100);
    --main-200: var(--neutral-200);
    --main-300: var(--neutral-300);
    --main-400: var(--neutral-400);
    --main-500: var(--neutral-500);
    --main-600: var(--neutral-600);
    --main-700: var(--neutral-700);
    --main-800: var(--neutral-800);
    --main-900: var(--neutral-900);
    --main-950: var(--neutral-950);
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }
}

@media not print {
  .dark {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    color-scheme: dark;
  }
}

@media print {
  .dark {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --main-50: var(--neutral-50);
    --main-100: var(--neutral-100);
    --main-200: var(--neutral-200);
    --main-300: var(--neutral-300);
    --main-400: var(--neutral-400);
    --main-500: var(--neutral-500);
    --main-600: var(--neutral-600);
    --main-700: var(--neutral-700);
    --main-800: var(--neutral-800);
    --main-900: var(--neutral-900);
    --main-950: var(--neutral-950);
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }
}

@media not print {
  .auto {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light dark;
  }

  @media (prefers-color-scheme: dark) {
    .auto {
      --lightningcss-light: ;
      --lightningcss-dark: initial;
    }
  }
}

@media print {
  .auto {
    --accent: var(--accent-500);
    accent-color: var(--accent);
    --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
    --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
    --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
    --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
    --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
    --background-500: var(--main-500);
    --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
    --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
    --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
    --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
    --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
    --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
    --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
    --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
    --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
    --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
    --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
    --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
    --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
    --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
    --main-50: var(--neutral-50);
    --main-100: var(--neutral-100);
    --main-200: var(--neutral-200);
    --main-300: var(--neutral-300);
    --main-400: var(--neutral-400);
    --main-500: var(--neutral-500);
    --main-600: var(--neutral-600);
    --main-700: var(--neutral-700);
    --main-800: var(--neutral-800);
    --main-900: var(--neutral-900);
    --main-950: var(--neutral-950);
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }
}

.m-slate {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--slate-50);
  --main-100: var(--slate-100);
  --main-200: var(--slate-200);
  --main-300: var(--slate-300);
  --main-400: var(--slate-400);
  --main-500: var(--slate-500);
  --main-600: var(--slate-600);
  --main-700: var(--slate-700);
  --main-800: var(--slate-800);
  --main-900: var(--slate-900);
  --main-950: var(--slate-950);
}

.m-neutral {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--neutral-50);
  --main-100: var(--neutral-100);
  --main-200: var(--neutral-200);
  --main-300: var(--neutral-300);
  --main-400: var(--neutral-400);
  --main-500: var(--neutral-500);
  --main-600: var(--neutral-600);
  --main-700: var(--neutral-700);
  --main-800: var(--neutral-800);
  --main-900: var(--neutral-900);
  --main-950: var(--neutral-950);
}

.m-gray {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--gray-50);
  --main-100: var(--gray-100);
  --main-200: var(--gray-200);
  --main-300: var(--gray-300);
  --main-400: var(--gray-400);
  --main-500: var(--gray-500);
  --main-600: var(--gray-600);
  --main-700: var(--gray-700);
  --main-800: var(--gray-800);
  --main-900: var(--gray-900);
  --main-950: var(--gray-950);
}

.m-zinc {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--zinc-50);
  --main-100: var(--zinc-100);
  --main-200: var(--zinc-200);
  --main-300: var(--zinc-300);
  --main-400: var(--zinc-400);
  --main-500: var(--zinc-500);
  --main-600: var(--zinc-600);
  --main-700: var(--zinc-700);
  --main-800: var(--zinc-800);
  --main-900: var(--zinc-900);
  --main-950: var(--zinc-950);
}

.m-stone {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--stone-50);
  --main-100: var(--stone-100);
  --main-200: var(--stone-200);
  --main-300: var(--stone-300);
  --main-400: var(--stone-400);
  --main-500: var(--stone-500);
  --main-600: var(--stone-600);
  --main-700: var(--stone-700);
  --main-800: var(--stone-800);
  --main-900: var(--stone-900);
  --main-950: var(--stone-950);
}

.m-red {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--red-50);
  --main-100: var(--red-100);
  --main-200: var(--red-200);
  --main-300: var(--red-300);
  --main-400: var(--red-400);
  --main-500: var(--red-500);
  --main-600: var(--red-600);
  --main-700: var(--red-700);
  --main-800: var(--red-800);
  --main-900: var(--red-900);
  --main-950: var(--red-950);
}

.m-orange {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--orange-50);
  --main-100: var(--orange-100);
  --main-200: var(--orange-200);
  --main-300: var(--orange-300);
  --main-400: var(--orange-400);
  --main-500: var(--orange-500);
  --main-600: var(--orange-600);
  --main-700: var(--orange-700);
  --main-800: var(--orange-800);
  --main-900: var(--orange-900);
  --main-950: var(--orange-950);
}

.m-amber {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--amber-50);
  --main-100: var(--amber-100);
  --main-200: var(--amber-200);
  --main-300: var(--amber-300);
  --main-400: var(--amber-400);
  --main-500: var(--amber-500);
  --main-600: var(--amber-600);
  --main-700: var(--amber-700);
  --main-800: var(--amber-800);
  --main-900: var(--amber-900);
  --main-950: var(--amber-950);
}

.m-yellow {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--yellow-50);
  --main-100: var(--yellow-100);
  --main-200: var(--yellow-200);
  --main-300: var(--yellow-300);
  --main-400: var(--yellow-400);
  --main-500: var(--yellow-500);
  --main-600: var(--yellow-600);
  --main-700: var(--yellow-700);
  --main-800: var(--yellow-800);
  --main-900: var(--yellow-900);
  --main-950: var(--yellow-950);
}

.m-lime {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--lime-50);
  --main-100: var(--lime-100);
  --main-200: var(--lime-200);
  --main-300: var(--lime-300);
  --main-400: var(--lime-400);
  --main-500: var(--lime-500);
  --main-600: var(--lime-600);
  --main-700: var(--lime-700);
  --main-800: var(--lime-800);
  --main-900: var(--lime-900);
  --main-950: var(--lime-950);
}

.m-green {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--green-50);
  --main-100: var(--green-100);
  --main-200: var(--green-200);
  --main-300: var(--green-300);
  --main-400: var(--green-400);
  --main-500: var(--green-500);
  --main-600: var(--green-600);
  --main-700: var(--green-700);
  --main-800: var(--green-800);
  --main-900: var(--green-900);
  --main-950: var(--green-950);
}

.m-emerald {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--emerald-50);
  --main-100: var(--emerald-100);
  --main-200: var(--emerald-200);
  --main-300: var(--emerald-300);
  --main-400: var(--emerald-400);
  --main-500: var(--emerald-500);
  --main-600: var(--emerald-600);
  --main-700: var(--emerald-700);
  --main-800: var(--emerald-800);
  --main-900: var(--emerald-900);
  --main-950: var(--emerald-950);
}

.m-teal {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--teal-50);
  --main-100: var(--teal-100);
  --main-200: var(--teal-200);
  --main-300: var(--teal-300);
  --main-400: var(--teal-400);
  --main-500: var(--teal-500);
  --main-600: var(--teal-600);
  --main-700: var(--teal-700);
  --main-800: var(--teal-800);
  --main-900: var(--teal-900);
  --main-950: var(--teal-950);
}

.m-cyan {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--cyan-50);
  --main-100: var(--cyan-100);
  --main-200: var(--cyan-200);
  --main-300: var(--cyan-300);
  --main-400: var(--cyan-400);
  --main-500: var(--cyan-500);
  --main-600: var(--cyan-600);
  --main-700: var(--cyan-700);
  --main-800: var(--cyan-800);
  --main-900: var(--cyan-900);
  --main-950: var(--cyan-950);
}

.m-sky {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--sky-50);
  --main-100: var(--sky-100);
  --main-200: var(--sky-200);
  --main-300: var(--sky-300);
  --main-400: var(--sky-400);
  --main-500: var(--sky-500);
  --main-600: var(--sky-600);
  --main-700: var(--sky-700);
  --main-800: var(--sky-800);
  --main-900: var(--sky-900);
  --main-950: var(--sky-950);
}

.m-blue {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--blue-50);
  --main-100: var(--blue-100);
  --main-200: var(--blue-200);
  --main-300: var(--blue-300);
  --main-400: var(--blue-400);
  --main-500: var(--blue-500);
  --main-600: var(--blue-600);
  --main-700: var(--blue-700);
  --main-800: var(--blue-800);
  --main-900: var(--blue-900);
  --main-950: var(--blue-950);
}

.m-indigo {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--indigo-50);
  --main-100: var(--indigo-100);
  --main-200: var(--indigo-200);
  --main-300: var(--indigo-300);
  --main-400: var(--indigo-400);
  --main-500: var(--indigo-500);
  --main-600: var(--indigo-600);
  --main-700: var(--indigo-700);
  --main-800: var(--indigo-800);
  --main-900: var(--indigo-900);
  --main-950: var(--indigo-950);
}

.m-violet {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--violet-50);
  --main-100: var(--violet-100);
  --main-200: var(--violet-200);
  --main-300: var(--violet-300);
  --main-400: var(--violet-400);
  --main-500: var(--violet-500);
  --main-600: var(--violet-600);
  --main-700: var(--violet-700);
  --main-800: var(--violet-800);
  --main-900: var(--violet-900);
  --main-950: var(--violet-950);
}

.m-purple {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--purple-50);
  --main-100: var(--purple-100);
  --main-200: var(--purple-200);
  --main-300: var(--purple-300);
  --main-400: var(--purple-400);
  --main-500: var(--purple-500);
  --main-600: var(--purple-600);
  --main-700: var(--purple-700);
  --main-800: var(--purple-800);
  --main-900: var(--purple-900);
  --main-950: var(--purple-950);
}

.m-fuchsia {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--fuchsia-50);
  --main-100: var(--fuchsia-100);
  --main-200: var(--fuchsia-200);
  --main-300: var(--fuchsia-300);
  --main-400: var(--fuchsia-400);
  --main-500: var(--fuchsia-500);
  --main-600: var(--fuchsia-600);
  --main-700: var(--fuchsia-700);
  --main-800: var(--fuchsia-800);
  --main-900: var(--fuchsia-900);
  --main-950: var(--fuchsia-950);
}

.m-pink {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--pink-50);
  --main-100: var(--pink-100);
  --main-200: var(--pink-200);
  --main-300: var(--pink-300);
  --main-400: var(--pink-400);
  --main-500: var(--pink-500);
  --main-600: var(--pink-600);
  --main-700: var(--pink-700);
  --main-800: var(--pink-800);
  --main-900: var(--pink-900);
  --main-950: var(--pink-950);
}

.m-rose {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--rose-50);
  --main-100: var(--rose-100);
  --main-200: var(--rose-200);
  --main-300: var(--rose-300);
  --main-400: var(--rose-400);
  --main-500: var(--rose-500);
  --main-600: var(--rose-600);
  --main-700: var(--rose-700);
  --main-800: var(--rose-800);
  --main-900: var(--rose-900);
  --main-950: var(--rose-950);
}

.slate {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--slate-50);
  --accentColor-100: var(--slate-100);
  --accentColor-200: var(--slate-200);
  --accentColor-300: var(--slate-300);
  --accentColor-400: var(--slate-400);
  --accentColor-500: var(--slate-500);
  --accentColor-600: var(--slate-600);
  --accentColor-700: var(--slate-700);
  --accentColor-800: var(--slate-800);
  --accentColor-900: var(--slate-900);
  --accentColor-950: var(--slate-950);
}

.neutral {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--neutral-50);
  --accentColor-100: var(--neutral-100);
  --accentColor-200: var(--neutral-200);
  --accentColor-300: var(--neutral-300);
  --accentColor-400: var(--neutral-400);
  --accentColor-500: var(--neutral-500);
  --accentColor-600: var(--neutral-600);
  --accentColor-700: var(--neutral-700);
  --accentColor-800: var(--neutral-800);
  --accentColor-900: var(--neutral-900);
  --accentColor-950: var(--neutral-950);
}

.gray {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--gray-50);
  --accentColor-100: var(--gray-100);
  --accentColor-200: var(--gray-200);
  --accentColor-300: var(--gray-300);
  --accentColor-400: var(--gray-400);
  --accentColor-500: var(--gray-500);
  --accentColor-600: var(--gray-600);
  --accentColor-700: var(--gray-700);
  --accentColor-800: var(--gray-800);
  --accentColor-900: var(--gray-900);
  --accentColor-950: var(--gray-950);
}

.zinc {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--zinc-50);
  --accentColor-100: var(--zinc-100);
  --accentColor-200: var(--zinc-200);
  --accentColor-300: var(--zinc-300);
  --accentColor-400: var(--zinc-400);
  --accentColor-500: var(--zinc-500);
  --accentColor-600: var(--zinc-600);
  --accentColor-700: var(--zinc-700);
  --accentColor-800: var(--zinc-800);
  --accentColor-900: var(--zinc-900);
  --accentColor-950: var(--zinc-950);
}

.stone {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--stone-50);
  --accentColor-100: var(--stone-100);
  --accentColor-200: var(--stone-200);
  --accentColor-300: var(--stone-300);
  --accentColor-400: var(--stone-400);
  --accentColor-500: var(--stone-500);
  --accentColor-600: var(--stone-600);
  --accentColor-700: var(--stone-700);
  --accentColor-800: var(--stone-800);
  --accentColor-900: var(--stone-900);
  --accentColor-950: var(--stone-950);
}

.red {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--red-50);
  --accentColor-100: var(--red-100);
  --accentColor-200: var(--red-200);
  --accentColor-300: var(--red-300);
  --accentColor-400: var(--red-400);
  --accentColor-500: var(--red-500);
  --accentColor-600: var(--red-600);
  --accentColor-700: var(--red-700);
  --accentColor-800: var(--red-800);
  --accentColor-900: var(--red-900);
  --accentColor-950: var(--red-950);
}

.orange {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--orange-50);
  --accentColor-100: var(--orange-100);
  --accentColor-200: var(--orange-200);
  --accentColor-300: var(--orange-300);
  --accentColor-400: var(--orange-400);
  --accentColor-500: var(--orange-500);
  --accentColor-600: var(--orange-600);
  --accentColor-700: var(--orange-700);
  --accentColor-800: var(--orange-800);
  --accentColor-900: var(--orange-900);
  --accentColor-950: var(--orange-950);
}

.amber {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--amber-50);
  --accentColor-100: var(--amber-100);
  --accentColor-200: var(--amber-200);
  --accentColor-300: var(--amber-300);
  --accentColor-400: var(--amber-400);
  --accentColor-500: var(--amber-500);
  --accentColor-600: var(--amber-600);
  --accentColor-700: var(--amber-700);
  --accentColor-800: var(--amber-800);
  --accentColor-900: var(--amber-900);
  --accentColor-950: var(--amber-950);
}

.yellow {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--yellow-50);
  --accentColor-100: var(--yellow-100);
  --accentColor-200: var(--yellow-200);
  --accentColor-300: var(--yellow-300);
  --accentColor-400: var(--yellow-400);
  --accentColor-500: var(--yellow-500);
  --accentColor-600: var(--yellow-600);
  --accentColor-700: var(--yellow-700);
  --accentColor-800: var(--yellow-800);
  --accentColor-900: var(--yellow-900);
  --accentColor-950: var(--yellow-950);
}

.lime {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--lime-50);
  --accentColor-100: var(--lime-100);
  --accentColor-200: var(--lime-200);
  --accentColor-300: var(--lime-300);
  --accentColor-400: var(--lime-400);
  --accentColor-500: var(--lime-500);
  --accentColor-600: var(--lime-600);
  --accentColor-700: var(--lime-700);
  --accentColor-800: var(--lime-800);
  --accentColor-900: var(--lime-900);
  --accentColor-950: var(--lime-950);
}

.green {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--green-50);
  --accentColor-100: var(--green-100);
  --accentColor-200: var(--green-200);
  --accentColor-300: var(--green-300);
  --accentColor-400: var(--green-400);
  --accentColor-500: var(--green-500);
  --accentColor-600: var(--green-600);
  --accentColor-700: var(--green-700);
  --accentColor-800: var(--green-800);
  --accentColor-900: var(--green-900);
  --accentColor-950: var(--green-950);
}

.emerald {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--emerald-50);
  --accentColor-100: var(--emerald-100);
  --accentColor-200: var(--emerald-200);
  --accentColor-300: var(--emerald-300);
  --accentColor-400: var(--emerald-400);
  --accentColor-500: var(--emerald-500);
  --accentColor-600: var(--emerald-600);
  --accentColor-700: var(--emerald-700);
  --accentColor-800: var(--emerald-800);
  --accentColor-900: var(--emerald-900);
  --accentColor-950: var(--emerald-950);
}

.teal {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--teal-50);
  --accentColor-100: var(--teal-100);
  --accentColor-200: var(--teal-200);
  --accentColor-300: var(--teal-300);
  --accentColor-400: var(--teal-400);
  --accentColor-500: var(--teal-500);
  --accentColor-600: var(--teal-600);
  --accentColor-700: var(--teal-700);
  --accentColor-800: var(--teal-800);
  --accentColor-900: var(--teal-900);
  --accentColor-950: var(--teal-950);
}

.cyan {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--cyan-50);
  --accentColor-100: var(--cyan-100);
  --accentColor-200: var(--cyan-200);
  --accentColor-300: var(--cyan-300);
  --accentColor-400: var(--cyan-400);
  --accentColor-500: var(--cyan-500);
  --accentColor-600: var(--cyan-600);
  --accentColor-700: var(--cyan-700);
  --accentColor-800: var(--cyan-800);
  --accentColor-900: var(--cyan-900);
  --accentColor-950: var(--cyan-950);
}

.sky {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--sky-50);
  --accentColor-100: var(--sky-100);
  --accentColor-200: var(--sky-200);
  --accentColor-300: var(--sky-300);
  --accentColor-400: var(--sky-400);
  --accentColor-500: var(--sky-500);
  --accentColor-600: var(--sky-600);
  --accentColor-700: var(--sky-700);
  --accentColor-800: var(--sky-800);
  --accentColor-900: var(--sky-900);
  --accentColor-950: var(--sky-950);
}

.blue {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--blue-50);
  --accentColor-100: var(--blue-100);
  --accentColor-200: var(--blue-200);
  --accentColor-300: var(--blue-300);
  --accentColor-400: var(--blue-400);
  --accentColor-500: var(--blue-500);
  --accentColor-600: var(--blue-600);
  --accentColor-700: var(--blue-700);
  --accentColor-800: var(--blue-800);
  --accentColor-900: var(--blue-900);
  --accentColor-950: var(--blue-950);
}

.indigo {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--indigo-50);
  --accentColor-100: var(--indigo-100);
  --accentColor-200: var(--indigo-200);
  --accentColor-300: var(--indigo-300);
  --accentColor-400: var(--indigo-400);
  --accentColor-500: var(--indigo-500);
  --accentColor-600: var(--indigo-600);
  --accentColor-700: var(--indigo-700);
  --accentColor-800: var(--indigo-800);
  --accentColor-900: var(--indigo-900);
  --accentColor-950: var(--indigo-950);
}

.violet {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--violet-50);
  --accentColor-100: var(--violet-100);
  --accentColor-200: var(--violet-200);
  --accentColor-300: var(--violet-300);
  --accentColor-400: var(--violet-400);
  --accentColor-500: var(--violet-500);
  --accentColor-600: var(--violet-600);
  --accentColor-700: var(--violet-700);
  --accentColor-800: var(--violet-800);
  --accentColor-900: var(--violet-900);
  --accentColor-950: var(--violet-950);
}

.purple {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--purple-50);
  --accentColor-100: var(--purple-100);
  --accentColor-200: var(--purple-200);
  --accentColor-300: var(--purple-300);
  --accentColor-400: var(--purple-400);
  --accentColor-500: var(--purple-500);
  --accentColor-600: var(--purple-600);
  --accentColor-700: var(--purple-700);
  --accentColor-800: var(--purple-800);
  --accentColor-900: var(--purple-900);
  --accentColor-950: var(--purple-950);
}

.fuchsia {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--fuchsia-50);
  --accentColor-100: var(--fuchsia-100);
  --accentColor-200: var(--fuchsia-200);
  --accentColor-300: var(--fuchsia-300);
  --accentColor-400: var(--fuchsia-400);
  --accentColor-500: var(--fuchsia-500);
  --accentColor-600: var(--fuchsia-600);
  --accentColor-700: var(--fuchsia-700);
  --accentColor-800: var(--fuchsia-800);
  --accentColor-900: var(--fuchsia-900);
  --accentColor-950: var(--fuchsia-950);
}

.pink {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--pink-50);
  --accentColor-100: var(--pink-100);
  --accentColor-200: var(--pink-200);
  --accentColor-300: var(--pink-300);
  --accentColor-400: var(--pink-400);
  --accentColor-500: var(--pink-500);
  --accentColor-600: var(--pink-600);
  --accentColor-700: var(--pink-700);
  --accentColor-800: var(--pink-800);
  --accentColor-900: var(--pink-900);
  --accentColor-950: var(--pink-950);
}

.rose {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--rose-50);
  --accentColor-100: var(--rose-100);
  --accentColor-200: var(--rose-200);
  --accentColor-300: var(--rose-300);
  --accentColor-400: var(--rose-400);
  --accentColor-500: var(--rose-500);
  --accentColor-600: var(--rose-600);
  --accentColor-700: var(--rose-700);
  --accentColor-800: var(--rose-800);
  --accentColor-900: var(--rose-900);
  --accentColor-950: var(--rose-950);
}

.symbols {
  font-variant: normal;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-rendering: geometricPrecision;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "";
  vertical-align: sub;
  -webkit-user-select: none;
  user-select: none;
  font-family: Material Symbols Rounded;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  overflow: hidden;
}

.symbols:before {
  font-family: lucide;
}

@position-try --popover-aligned-bottom-right {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + 2px) ; right: anchor(right) ; transform-origin: bottom right; min-inline-size: anchor-size(self-inline) ;
}

@position-try --popover-aligned-bottom-left {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + 2px) ; left: anchor(left) ; transform-origin: bottom left; min-inline-size: anchor-size(self-inline) ;
}

@position-try --popover-aligned-left-top {
  left: auto; right: auto; top: auto; bottom: auto; left: calc(anchor(right)  + 2px) ; top: anchor(top) ; transform-origin: left top; min-inline-size: fit-content;
}

@position-try --popover-aligned-right-top {
  left: auto; right: auto; top: auto; bottom: auto; right: calc(anchor(left)  + 2px) ; top: anchor(top) ; transform-origin: right top; min-inline-size: fit-content;
}

@position-try --anchor-aligned-bottom-left {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + .25em) ; left: anchor(left) ; transform-origin: bottom left;
}

@position-try --anchor-aligned-bottom-right {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + .25em) ; right: anchor(right) ; transform-origin: bottom right;
}

@position-try --anchor-aligned-top-left {
  left: auto; right: auto; top: auto; bottom: auto; top: calc(anchor(bottom)  + .25em) ; left: anchor(left) ; transform-origin: top left;
}

@position-try --anchor-aligned-top-right {
  left: auto; right: auto; top: auto; bottom: auto; top: calc(anchor(bottom)  + .25em) ; right: anchor(right) ; transform-origin: top right;
}

.error {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--red-50);
  --accentColor-100: var(--red-100);
  --accentColor-200: var(--red-200);
  --accentColor-300: var(--red-300);
  --accentColor-400: var(--red-400);
  --accentColor-500: var(--red-500);
  --accentColor-600: var(--red-600);
  --accentColor-700: var(--red-700);
  --accentColor-800: var(--red-800);
  --accentColor-900: var(--red-900);
  --accentColor-950: var(--red-950);
  color: var(--accentColor-700);
}

.error:before {
  font-variant: normal;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-rendering: geometricPrecision;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "error";
  vertical-align: sub;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: text-bottom;
  font-family: Material Symbols Rounded;
  font-size: 150%;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.cError {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  margin-block: auto;
  margin-inline: auto;
  display: flex;
}

.cError > .symbols {
  font-size: 48px;
}

.cError * {
  width: 100%;
  margin: .3em;
}

.cError i {
  margin-bottom: 0;
}

.shimmering, [data-busy="true"] {
  opacity: .7;
  filter: brightness(1.2) blur(2px);
  animation: 1.5s ease-in-out .625s infinite shimmer;
  mask-image: linear-gradient(-45deg, #000 0% 33%, #0000 50%, #000 66% 100%);
  mask-size: 300% 300%;
  mask-repeat: no-repeat;
  mask-mode: alpha;
  pointer-events: none !important;
}

@keyframes shimmer {
  0% {
    mask-position: 0 0;
  }

  100% {
    mask-position: 100% 100%;
  }
}

.shimmerLarge {
  opacity: .7;
  filter: brightness(1.2) blur(8px);
  pointer-events: none !important;
}

.shimmerInvert {
  opacity: .7;
  filter: brightness(1.2) blur(2px);
  pointer-events: none;
  background-image: linear-gradient(-45deg, #0000 0% 33%, #0004 50%, #0000 66% 100%);
  background-repeat: no-repeat;
  background-size: 300% 300%;
  animation: 1.5s ease-in-out .625s infinite shimmerBG;
}

@keyframes shimmerBG {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 100%;
  }
}

.inputEntry {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--neutral-50);
  --main-100: var(--neutral-100);
  --main-200: var(--neutral-200);
  --main-300: var(--neutral-300);
  --main-400: var(--neutral-400);
  --main-500: var(--neutral-500);
  --main-600: var(--neutral-600);
  --main-700: var(--neutral-700);
  --main-800: var(--neutral-800);
  --main-900: var(--neutral-900);
  --main-950: var(--neutral-950);
  background: var(--background-50);
  color: var(--background-700);
  border: 1px solid var(--background-300);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-block: 2px;
  padding: 4px;
  font-weight: 400;
  transition: background .25s, color .25s, border .25s, font-weight .25s, box-shadow .25s;
}

.inputEntry:focus, .inputEntry.highlighted {
  background-color: var(--accent-50);
  border-color: var(--accent-400);
  outline: none;
}

.inputEntry:focus:not(.large), .inputEntry.highlighted:not(.large) {
  color: var(--background-900);
  border-size: 3px;
  font-weight: 500;
}

.inputEntry:last-of-type {
  margin-bottom: .5em;
}

.inputEntry.transparent {
  box-shadow: none;
  background: none;
}

.inputEntry.transparent::webkit-resizer {
  display: none;
}

.inputEntry[data-invalid="true"] {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --accentColor-50: var(--red-50);
  --accentColor-100: var(--red-100);
  --accentColor-200: var(--red-200);
  --accentColor-300: var(--red-300);
  --accentColor-400: var(--red-400);
  --accentColor-500: var(--red-500);
  --accentColor-600: var(--red-600);
  --accentColor-700: var(--red-700);
  --accentColor-800: var(--red-800);
  --accentColor-900: var(--red-900);
  --accentColor-950: var(--red-950);
  border-color: var(--accent-400);
  color: var(--accent-800);
}

label:not([class]), .cLabel {
  text-transform: uppercase;
  color: var(--background-700);
  padding-left: 4px;
  font-weight: 600;
}

.searchBox {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  height: 30px;
  display: grid;
}

.searchBox input {
  border-radius: 16px;
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  margin-top: 0;
  padding-left: 2em;
  transition: background .25s, color .25s, border .25s, font-weight .25s, border-radius .25s;
  margin-bottom: 0 !important;
}

.searchBox:before {
  font-variant: normal;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-rendering: geometricPrecision;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "search";
  vertical-align: sub;
  -webkit-user-select: none;
  user-select: none;
  color: var(--background-600);
  z-index: 2;
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
  width: 1em;
  padding-inline: 6px;
  font-family: Material Symbols Rounded;
  font-size: 1.25em;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  top: 0;
  transform: rotateY(180deg);
}

.controlGroup {
  grid-template-rows: auto auto;
  grid-auto-columns: minmax(250px, auto);
  grid-auto-flow: column;
  column-gap: 1em;
  margin-block: 1em;
  padding-right: .25em;
  display: grid;
}

.controlGroup > h1, .controlGroup > h2, .controlGroup > h3, .controlGroup > h4, .controlGroup > h5, .controlGroup > h6, .controlGroup > p, .controlGroup > pre {
  grid-row: 1;
}

.controlGroup > * {
  grid-row: 2;
}

.controlGroup.center {
  align-items: center;
}

.titlebar {
  background: var(--background-100);
  color: var(--text);
  padding: 1em;
  grid-template-columns: auto fit-content(100%);
  align-items: center;
  padding-block: .75em;
  display: grid;
  box-shadow: 0 0 10px #00000080;
}

.titlebar > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  grid-area: 1 / 1 / 2 / 2;
  overflow: hidden;
}

.titlebar > .text > h2 {
  color: var(--background-600);
  font-size: 18px;
  font-weight: 500;
}

.titlebar > .text > h1 {
  font-size: 24px;
  font-weight: 600;
}

.titlebar > .buttons {
  grid-area: 1 / 2 / 2 / 3;
  gap: 1em;
  display: flex;
}

.titlebar > .buttons > button {
  color: var(--text);
  text-shadow: 0 0 0px var(--background-400);
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 36px;
  line-height: 0;
  transition: text-shadow .25s, font-weight .25s;
}

.titlebar > .buttons > button:hover {
  text-shadow: 0 0 8px var(--background-400);
  font-weight: 900;
}

.titlebar[data-thin="true"] > .text {
  flex-direction: row;
  align-items: center;
  gap: 1em;
  display: flex;
}

.titlebar[data-thin="true"] > .text h1 {
  font-size: 22px;
}

.titlebar[data-thin="true"] > .text h2 {
  font-size: 16px;
}

.titlebar[data-thin="true"] > .buttons > button {
  font-size: 24px;
}

@position-try --popover-aligned-bottom-right {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + 2px) ; right: anchor(right) ; transform-origin: bottom right; min-inline-size: anchor-size(self-inline) ;
}

@position-try --popover-aligned-bottom-left {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + 2px) ; left: anchor(left) ; transform-origin: bottom left; min-inline-size: anchor-size(self-inline) ;
}

@position-try --popover-aligned-left-top {
  left: auto; right: auto; top: auto; bottom: auto; left: calc(anchor(right)  + 2px) ; top: anchor(top) ; transform-origin: left top; min-inline-size: fit-content;
}

@position-try --popover-aligned-right-top {
  left: auto; right: auto; top: auto; bottom: auto; right: calc(anchor(left)  + 2px) ; top: anchor(top) ; transform-origin: right top; min-inline-size: fit-content;
}

@position-try --anchor-aligned-bottom-left {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + .25em) ; left: anchor(left) ; transform-origin: bottom left;
}

@position-try --anchor-aligned-bottom-right {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + .25em) ; right: anchor(right) ; transform-origin: bottom right;
}

@position-try --anchor-aligned-top-left {
  left: auto; right: auto; top: auto; bottom: auto; top: calc(anchor(bottom)  + .25em) ; left: anchor(left) ; transform-origin: top left;
}

@position-try --anchor-aligned-top-right {
  left: auto; right: auto; top: auto; bottom: auto; top: calc(anchor(bottom)  + .25em) ; right: anchor(right) ; transform-origin: top right;
}

.preload {
  position: fixed;
  inset: 0;
}

.preload.initApp {
  grid-template-columns: 300px 1fr;
  display: grid;
}

.preload.initApp .preloadSidebar {
  background-color: var(--main-900);
  background-image: linear-gradient(to left, var(--main-950), var(--main-900));
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-size: 8px 100%;
}

.preload.initApp .preloadTaskState > h1, .preload.initApp .preloadTaskState > p {
  font-size: .5em;
  display: none;
}

.preloadTaskState {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin-block: auto;
  margin-inline: auto;
  display: flex;
}

.preloadTaskState > * {
  width: 100%;
  margin: .1em;
}

.preloadTaskState i {
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 150%;
}

.preloadTaskState tr {
  color: var(--main-800);
  font-family: var(--font-family);
}

.preloadTaskState tr.status-done {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--green-50);
  --main-100: var(--green-100);
  --main-200: var(--green-200);
  --main-300: var(--green-300);
  --main-400: var(--green-400);
  --main-500: var(--green-500);
  --main-600: var(--green-600);
  --main-700: var(--green-700);
  --main-800: var(--green-800);
  --main-900: var(--green-900);
  --main-950: var(--green-950);
  font-weight: 500;
}

.preloadTaskState tr.status-error {
  --accent: var(--accent-500);
  accent-color: var(--accent);
  --text: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --background: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-50: var(--lightningcss-light, var(--main-50)) var(--lightningcss-dark, var(--main-950));
  --background-100: var(--lightningcss-light, var(--main-100)) var(--lightningcss-dark, var(--main-900));
  --background-200: var(--lightningcss-light, var(--main-200)) var(--lightningcss-dark, var(--main-800));
  --background-300: var(--lightningcss-light, var(--main-300)) var(--lightningcss-dark, var(--main-700));
  --background-400: var(--lightningcss-light, var(--main-400)) var(--lightningcss-dark, var(--main-600));
  --background-500: var(--main-500);
  --background-600: var(--lightningcss-light, var(--main-600)) var(--lightningcss-dark, var(--main-400));
  --background-700: var(--lightningcss-light, var(--main-700)) var(--lightningcss-dark, var(--main-300));
  --background-800: var(--lightningcss-light, var(--main-800)) var(--lightningcss-dark, var(--main-200));
  --background-900: var(--lightningcss-light, var(--main-900)) var(--lightningcss-dark, var(--main-100));
  --background-950: var(--lightningcss-light, var(--main-950)) var(--lightningcss-dark, var(--main-50));
  --accent-50: var(--lightningcss-light, var(--accentColor-50)) var(--lightningcss-dark, var(--accentColor-950));
  --accent-100: var(--lightningcss-light, var(--accentColor-100)) var(--lightningcss-dark, var(--accentColor-900));
  --accent-200: var(--lightningcss-light, var(--accentColor-200)) var(--lightningcss-dark, var(--accentColor-800));
  --accent-300: var(--lightningcss-light, var(--accentColor-300)) var(--lightningcss-dark, var(--accentColor-700));
  --accent-400: var(--lightningcss-light, var(--accentColor-400)) var(--lightningcss-dark, var(--accentColor-600));
  --accent-500: var(--accentColor-500);
  --accent-600: var(--lightningcss-light, var(--accentColor-600)) var(--lightningcss-dark, var(--accentColor-400));
  --accent-700: var(--lightningcss-light, var(--accentColor-700)) var(--lightningcss-dark, var(--accentColor-300));
  --accent-800: var(--lightningcss-light, var(--accentColor-800)) var(--lightningcss-dark, var(--accentColor-200));
  --accent-900: var(--lightningcss-light, var(--accentColor-900)) var(--lightningcss-dark, var(--accentColor-100));
  --accent-950: var(--lightningcss-light, var(--accentColor-950)) var(--lightningcss-dark, var(--accentColor-50));
  --main-50: var(--red-50);
  --main-100: var(--red-100);
  --main-200: var(--red-200);
  --main-300: var(--red-300);
  --main-400: var(--red-400);
  --main-500: var(--red-500);
  --main-600: var(--red-600);
  --main-700: var(--red-700);
  --main-800: var(--red-800);
  --main-900: var(--red-900);
  --main-950: var(--red-950);
  font-weight: 600;
}

.preloadTaskState tr.status-current {
  font-weight: 800;
}

.preloadTaskState tr.status-pending {
  font-weight: 400;
}

.preloadTaskState tr * {
  font-weight: inherit;
}

.preloadTaskState pre {
  white-space: break-spaces;
}

@position-try --popover-aligned-bottom-right {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + 2px) ; right: anchor(right) ; transform-origin: bottom right; min-inline-size: anchor-size(self-inline) ;
}

@position-try --popover-aligned-bottom-left {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + 2px) ; left: anchor(left) ; transform-origin: bottom left; min-inline-size: anchor-size(self-inline) ;
}

@position-try --popover-aligned-left-top {
  left: auto; right: auto; top: auto; bottom: auto; left: calc(anchor(right)  + 2px) ; top: anchor(top) ; transform-origin: left top; min-inline-size: fit-content;
}

@position-try --popover-aligned-right-top {
  left: auto; right: auto; top: auto; bottom: auto; right: calc(anchor(left)  + 2px) ; top: anchor(top) ; transform-origin: right top; min-inline-size: fit-content;
}

@position-try --anchor-aligned-bottom-left {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + .25em) ; left: anchor(left) ; transform-origin: bottom left;
}

@position-try --anchor-aligned-bottom-right {
  left: auto; right: auto; top: auto; bottom: auto; bottom: calc(anchor(top)  + .25em) ; right: anchor(right) ; transform-origin: bottom right;
}

@position-try --anchor-aligned-top-left {
  left: auto; right: auto; top: auto; bottom: auto; top: calc(anchor(bottom)  + .25em) ; left: anchor(left) ; transform-origin: top left;
}

@position-try --anchor-aligned-top-right {
  left: auto; right: auto; top: auto; bottom: auto; top: calc(anchor(bottom)  + .25em) ; right: anchor(right) ; transform-origin: top right;
}

.cPopover {
  box-sizing: border-box;
  min-inline-size: anchor-size(self-inline);
  opacity: 0;
  transition: transform .125s ease-out, opacity .125s ease-out, overlay .125s ease-out allow-discrete, display .125s ease-out allow-discrete;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  transform: scale(.75);
}

.cPopover.align-left {
  inset: auto;
  top: calc(anchor(bottom)  + 2px);
  left: anchor(left);
  transform-origin: 0 0;
  position-try-fallbacks: --popover-aligned-bottom-left, --popover-aligned-left-top, --popover-aligned-right-top;
}

.cPopover.align-right {
  inset: auto;
  top: calc(anchor(bottom)  + 2px);
  right: anchor(right);
  transform-origin: 100% 0;
  position-try-fallbacks: --popover-aligned-bottom-right, --popover-aligned-right-top, --popover-aligned-left-top;
}

.cPopover::backdrop {
  opacity: 0;
  transition: opacity .125s ease-out;
}

.cPopover:not(:popover-open) {
  display: none;
}

.cPopover::backdrop {
  background: #000;
}

.cPopover:popover-open {
  opacity: 1;
  transform: scale(1);
}

@starting-style {
  .cPopover {
    transition: transform .125s ease-out, opacity .125s ease-out, overlay .125s ease-out allow-discrete, display .125s ease-out allow-discrete;
  }

  .cPopover::backdrop {
    transition: opacity .125s ease-out;
  }

  .cPopover:popover-open {
    opacity: 0;
    transform: scale(.75);
  }

  .cPopover:popover-open::backdrop {
    opacity: 0;
  }
}

body {
  margin: 0;
  overflow: hidden;
}

[inert] {
  opacity: .5;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.grow {
  flex-grow: 1;
}

[data-invisible="true"] {
  display: none !important;
}

button, a {
  cursor: pointer;
}

textarea {
  resize: vertical;
  min-height: 2em;
}

h1, h2, h3, h4, h5, h6, p, pre {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  overflow-wrap: anywhere;
}

.upperSubtitle {
  text-transform: uppercase;
  color: var(--background-700);
  font-size: 100%;
  font-weight: 600;
}

a:not([class]), .link {
  color: var(--accent);
  text-shadow: 0 0 .3em var(--accent-200);
  text-decoration: none;
  transition: text-shadow .25s, color .25s;
}

a:not([class]):hover, .link:hover {
  text-shadow: 0 0 .5em var(--accent-400);
  color: var(--accent-600);
  text-decoration: underline;
}

div[id="__parcel__error__overlay__"] > div, #testerroroverlay > div {
  box-sizing: border-box;
  background: var(--background-50) !important;
  opacity: 1 !important;
}

div[id="__parcel__error__overlay__"] > div > div, #testerroroverlay > div > div {
  color: #fafafa;
  background: #0a0a0a;
  border-radius: 4px;
  margin-top: 125px;
  padding: 1em;
  box-shadow: 0 0 .5em #0a0a0a88;
}

div[id="__parcel__error__overlay__"] > div > div a, #testerroroverlay > div > div a {
  text-shadow: none;
}

div[id="__parcel__error__overlay__"] > div > div > div:first-child, #testerroroverlay > div > div > div:first-child {
  margin-top: 0 !important;
}

div[id="__parcel__error__overlay__"] > div > div > div:first-child:before, #testerroroverlay > div > div > div:first-child:before {
  content: "ERROR DETAILS";
  color: #a3a3a3;
  display: block;
  font-family: var(--font-family) !important;
}

div[id="__parcel__error__overlay__"] > div > div:after, #testerroroverlay > div > div:after {
  font-variant: normal;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-rendering: geometricPrecision;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "error";
  vertical-align: sub;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  width: 100%;
  font-family: Material Symbols Rounded;
  font-size: 64px;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  position: fixed;
  top: 24px;
  left: 0;
  color: var(--text) !important;
  font-weight: 700 !important;
}

div[id="__parcel__error__overlay__"] > div > div:before, #testerroroverlay > div > div:before {
  content: "Sorry! This application is currently out of order.";
  font-size: 1.5em;
  top: 90px;
  font-family: var(--font-family) !important;
}

div[id="__parcel__error__overlay__"] > div > div:before, div[id="__parcel__error__overlay__"] > div > div:after, #testerroroverlay > div > div:before, #testerroroverlay > div > div:after {
  text-align: center;
  width: 100%;
  position: fixed;
  left: 0;
  color: var(--text) !important;
  font-weight: 700 !important;
}

small {
  color: var(--background-600);
  font-size: .75em;
  font-style: italic;
}

.fadeIn {
  animation: .125s ease-in-out fade-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
    filter: saturate(.2);
  }

  to {
    opacity: 1;
    filter: saturate();
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    filter: saturate();
  }

  to {
    opacity: 0;
    filter: saturate(.2);
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-from-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-to-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes slide-to-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes scale-in {
  0% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.8);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  text-align: center;
  animation: 1s infinite spin;
}

progress {
  flex-grow: 1;
}

.width-100 {
  width: 100%;
}

@media print {
  button, input[type="file"], textarea {
    display: none !important;
  }

  * {
    box-shadow: none !important;
  }
}

[data-unsaved="true"] {
  --unsaved-glow: var(--lightningcss-light, var(--red-200)) var(--lightningcss-dark, var(--red-900));
  animation: 1s ease-in-out infinite alternate unsaved;
}

@keyframes unsaved {
  50% {
    background: var(--unsaved-glow);
    box-shadow: 0 0 2.5em var(--unsaved-glow);
    transform: scale(1.05);
  }
}

.clampedText {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.noTransition, .noTransition * {
  transition: none !important;
}
/*# sourceMappingURL=index.9ebc18a9.css.map */
